export default {
  name: 'PercentChangedWithArrow',
  props: {
    percentChanged : [Number, String]
  },
  data: function () {
    return {
      displayedPercent: undefined,
      displayedIcon: undefined,
      displayedColor: undefined
    };
  },
  mounted() {
    this.updateDisplayedElements();
  },
  methods: {
    updateDisplayedElements: function () {
      if (this.percentChanged == '-') {
        this.displayedPercent = '-';
        this.displayedIcon = '';
        this.displayedColor = '';
      } else if (this.percentChanged < 0) {
        this.displayedPercent = Math.abs(this.percentChanged);
        this.displayedIcon = '$riwIconSet_decreased';
        this.displayedColor = 'corporateBlue';
      } else {
        this.displayedPercent = this.percentChanged;
        this.displayedIcon = '$riwIconSet_increased';
        this.displayedColor = 'amber';
      }
    }
  },
  watch: {
    percentChanged: function (newVal, oldVal) {
      this.updateDisplayedElements();
    }
  }
};
