import apiEndpoints from '@/models/common/api-endpoints';
import dateTimeFormatter from '@/models/common/date-time-formatter';
import dayjs from 'dayjs';
import generalFunctions from '@/models/common/general-functions';
import { mapGetters } from 'vuex';
import percentChangedWithArrow from '@/components/PercentChangedWithArrow';

const quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(quarterOfYear);

export default {
  name: 'witness-summary',
  components: {
    percentChangedWithArrow
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    requestDetails: {
      type: Object,
      default: undefined
    },
    mode: {
      type: String,
      default: undefined
    }, // avgDuration | total | count - changes the 'focus' of the summary (changes the way it is displayed)
    requiresInterval: {
      type: Boolean,
      default: false
    },
    dashboardView: {
      type: Boolean,
      default: false
    },
    addHistoricalResultToStore: {
      type: Boolean,
      default: false
    },
    hideAddButton: {
      type: Boolean,
      default: false
    },
    showManagementControls: {
      type: Boolean,
      default: false
    },
    showDescription: {
      type: Boolean,
      default: false
    },
    showDragable: { // this shows a different cursor on hover over the card
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      summaryData: {},
      loading: true,
      added: false,
      description: undefined,
      apiStartDate: undefined,
      endDate: undefined,
      apiGroupBy: 'day',
      apiDateMultiplier: 1,
      uniqueID: generalFunctions.generateUniqueID()
    };
  },
  computed: {
    ...mapGetters({
      dateFilter: 'dateFilter'
    }),
    noData: function () {
      if (!this.loading) {
        if(this.noSummaryData){
          return true;
        }
        let expected = {'current':{'count':0,'countWithDuration':0,'averageDuration':'00s','totalDuration':'00s'},'previous':{'count':0,'countWithDuration':0,'averageDuration':'00s','totalDuration':'00s','deltaPercents':{'count':'-','avgDuration':'-','totalDuration':'-'}},'averagePrevious':{'count':0,'countWithDuration':0,'averageDuration':'00s','totalDuration':'00s','deltaPercents':{'count':'-','avgDuration':'-','totalDuration':'-'}}};
        return (generalFunctions.deepCompare(this.summaryData, expected));
      }
      return false;
    },
    noSummaryData: function () {
      return generalFunctions.isEmptyObject(this.summaryData);
    },
    currentDateRangeString: function () {
      return dateTimeFormatter.formatDateRangeString(this.dateFilter.startDate, this.dateFilter.endDate);
    },
    previousDateRangeString: function () {
      const startDate = this.previousPeriodStartDate();
      const endDate = this.previousPeriodEndDate();
      return dateTimeFormatter.formatDateRangeString(startDate, endDate);
    },
    longTermComparisonDateRangeString: function () {
      const startDate = this.apiStartDate;
      const endDate = this.previousPeriodEndDate();
      return dateTimeFormatter.formatDateRangeString(startDate, endDate);
    }
  },
  methods: {
    previousPeriodEndDate: function () {
      if (this.dateFilter && this.dateFilter.startDate) {
        return dayjs(this.dateFilter.startDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD');
      }
      
      return;
    },
    previousPeriodStartDate: function () {
      if (this.dateFilter && this.dateFilter.startDate && this.dateFilter.jumpAmount && this.dateFilter.jumpPeriod) {
        return dayjs(this.dateFilter.startDate, 'YYYY-MM-DD').subtract(this.dateFilter.jumpAmount, this.dateFilter.jumpPeriod).format('YYYY-MM-DD');
      }
      
      return;
    },
    processCustomDateRangeApiResult: function (apiResult) {
      
      const summaryData = {
        current: {},
        previous: {},
        averagePrevious: {}
      };

      summaryData.current = {
        count: apiResult.count,
        countWithDuration: apiResult.countwithduration,
        averageDuration: dateTimeFormatter.deconstructDateTimeSeconds(Math.round(apiResult.averagetimeseconds)),
        totalDuration: dateTimeFormatter.deconstructDateTimeSeconds(apiResult.totaltimeseconds)
      };
      
      return summaryData;
    },
    processApiResult: function (apiResult) {
      
      const summaryData = {
        current: {},
        previous: {},
        averagePrevious: {}
      };

      summaryData.current = {
        count: apiResult.counts.thisPeriod,
        countWithDuration: apiResult.totalDurations.countThisPeriod,
        averageDuration: dateTimeFormatter.deconstructDateTimeSeconds(Math.round(apiResult.averageDurations.thisPeriod)),
        totalDuration: dateTimeFormatter.deconstructDateTimeSeconds(apiResult.totalDurations.thisPeriod)
      };

      summaryData.previous = {
        count: apiResult.counts.lastPeriod,
        countWithDuration: apiResult.totalDurations.countLastPeriod,
        averageDuration: dateTimeFormatter.deconstructDateTimeSeconds(Math.round(apiResult.averageDurations.lastPeriod)),
        totalDuration: dateTimeFormatter.deconstructDateTimeSeconds(apiResult.totalDurations.lastPeriod),
        deltaPercents: {
          count: apiResult.counts.lastPeriod == 0 ? '-' : Math.round(apiResult.counts.variationLastPeriod),
          avgDuration: apiResult.totalDurations.countLastPeriod == 0 ? '-' : Math.round(apiResult.averageDurations.variationLastPeriod),
          totalDuration: apiResult.totalDurations.countLastPeriod == 0 ? '-' : Math.round(apiResult.totalDurations.variationLastPeriod)
        }
      };
      
      summaryData.averagePrevious = {
        count: apiResult.counts.allPreviousPeriods,
        countWithDuration: apiResult.totalDurations.countAllPreviousPeriods,
        averageDuration: dateTimeFormatter.deconstructDateTimeSeconds(Math.round(apiResult.averageDurations.allPreviousPeriods)),
        totalDuration: dateTimeFormatter.deconstructDateTimeSeconds(apiResult.totalDurations.allPreviousPeriods),
        deltaPercents: {
          count: apiResult.counts.allPreviousPeriods == 0 ? '-' : Math.round(apiResult.counts.variationPreviousPeriods),
          avgDuration: apiResult.totalDurations.countAllPreviousPeriods == 0 ? '-' : Math.round(apiResult.averageDurations.variationPreviousPeriods),
          totalDuration: apiResult.totalDurations.countAllPreviousPeriods == 0 ? '-' : Math.round(apiResult.totalDurations.variationPreviousPeriods)
        }
      };

      return summaryData;
    },

    retrieveSummaryData: async function () {
      this.loading = true;
      this.summaryData = {};

      this.$store.commit('setHistoricalChartData', []);

      this.updateApiDateData();

      var response;
      if (this.requiresInterval) {
        response = await apiEndpoints.getWitnessIntervalSummary(
          this.requestDetails.clinicId,
          this.requestDetails.intervalId,
          this.apiStartDate,
          this.endDate,
          this.apiGroupBy,
          'WitnessSummaryComponentIntervalSummary' + this.uniqueID
        );
      } else {
        response = await apiEndpoints.getAllWitnessEventsSummary(
          this.requestDetails.clinicIds,
          this.requestDetails.nonProcedureEvents,
          this.requestDetails.events,
          this.requestDetails.locations,
          this.requestDetails.operators,
          this.apiStartDate,
          this.endDate,
          this.apiGroupBy,
          'WitnessSummaryComponentEventsSummary' + this.uniqueID
        );
        
        if (response.result && response.mostRecentWithCancelKey) {
          if (this.addHistoricalResultToStore) {
            this.$store.commit('setHistoricalChartData', response.data.data);
          }
        }
      }

      // set summaryData
      if (response.result && response.mostRecentWithCancelKey) {
        if (this.apiGroupBy == 'all' && response.data && response.data.data && response.data.data.length > 0) {
          this.summaryData = this.processCustomDateRangeApiResult(response.data.data[0]);
        } else if (response.data && response.data.summary) {
          this.summaryData = this.processApiResult(response.data.summary);
        }
        this.loading = false;
      }else if (!response.result && !response?.error?.isCancel) {
        this.loading = false;
      }
    },
    updateApiDateData: function () {
      const jumpAmount = this.dateFilter.jumpAmount;
      const jumpPeriod = this.dateFilter.jumpPeriod;
      const currentStartDate = this.dateFilter.startDate;
      this.endDate = this.dateFilter.endDate;

      let apiGroupBy = 'all';

      if (jumpAmount == 1) {
        apiGroupBy = jumpPeriod;
      } else if (jumpAmount > 1) {
        apiGroupBy = jumpAmount + 'days';
      }
      
      let apiDateMultiplier = 1;

      if (jumpAmount == 1 && jumpPeriod == 'day') {
        apiDateMultiplier = 7;
      } else if (jumpAmount == 7 || jumpPeriod == 'week' || jumpAmount == 90 || jumpPeriod == 'quarter') {
        apiDateMultiplier = 4;
      } else if (jumpAmount == 30 || jumpPeriod == 'month') {
        apiDateMultiplier = 12;
      } else if (jumpAmount == 365 || jumpPeriod == 'year') {
        apiDateMultiplier = 5;
      }
      
      const formattedStartDate = dayjs(currentStartDate, 'YYYY-MM-DD');

      if (apiDateMultiplier == 1) {
        this.apiStartDate = currentStartDate;
      } else {
        this.apiStartDate = formattedStartDate.subtract(apiDateMultiplier * jumpAmount, jumpPeriod).format('YYYY-MM-DD');
      }
      this.apiGroupBy = apiGroupBy;
      this.apiDateMultiplier = apiDateMultiplier;
    },
    renameMetric: async function () {
      this.$emit('renameClicked');
    },
    removeMetricFromConfigs: async function () {
      this.$emit('removeClicked');
    },
    addMetricToConfigs: async function () {
      const response = await apiEndpoints.getWitnessDashboardConfigs();

      if (response.result) {
        let currentConfig = {
          config: []
        };
        let dashboardId = '';
  
        // check if we have an existing config
        if (Array.isArray(response.data) && response.data.length > 0 && 'config' in response.data[0] && response.data[0].config) {
          currentConfig = generalFunctions.deepCopy(response.data[0]);
          dashboardId = response.data[0].dashboardid;
        }
  
        // construct the new config entry
        const newEntry = {
          name: this.constructUniqueDescription(),
          mode: this.mode,
          requiresInterval: this.requiresInterval,
          requestDetails: this.requestDetails
        };
  
        // add new config entry to currentConfig
        currentConfig.config.push(newEntry);
  
        if (!('name' in currentConfig) || !currentConfig.name) {
          currentConfig.name = 'Metrics for this User';
        }
  
        if (dashboardId) {
          // do a put to update the existing configuration
          const response = await apiEndpoints.putWitnessDashboardConfig(dashboardId, currentConfig);
          
          if (response.result) {
            this.added = true;
          }
        } else {
          // do a post to create a new configuration
          const response = await apiEndpoints.postWitnessDashboardConfig(currentConfig);
          
          if (response.result) {
            this.added = true;
          }
        }
      }

    },
    constructUniqueDescription: function () {
      if (this.requiresInterval) {
        return this.requestDetails.intervalTitle;
      }

      var constructedDescription = '';

      switch (this.mode) {
        case 'avgDuration':
          constructedDescription += 'Avg. Duration';
          break;
        case 'total':
          constructedDescription += 'Total Duration';
          break;
        case 'count':
          constructedDescription += 'Count';
          break;
      }

      constructedDescription += ' of Events - ';
      
      var multiClinic = true;
      
      if (this.requestDetails.clinicIds !== null) {
        if (this.requestDetails.clinicIds.length == 1) {
          multiClinic = false;
          constructedDescription += 'Clinic: ';
        } else {
          constructedDescription += 'Clinics: ';
        }
        for (let clinicId of this.requestDetails.clinicIds) {
          constructedDescription += generalFunctions.getClinicNameFromClinicGuid(clinicId) + ', ';
        }
        constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      } else {
        constructedDescription += 'All Clinics | ';
      }

      if (this.requestDetails.events !== null) {
        if (this.requestDetails.events.length == 1) {
          constructedDescription += 'Witness Point: ';
        } else {
          constructedDescription += 'Witness Points: ';
        }
        for (let event of this.requestDetails.events) {
          constructedDescription += event + ', ';
        }
        if (this.requestDetails.events.length == 0) {
          constructedDescription += 'None, ';
        }
        constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      }

      var nonProcedureEventCount = 0;
      Object.values(this.requestDetails.nonProcedureEvents).forEach(eventValue => {
        if (eventValue) {
          nonProcedureEventCount += 1;
        }
      });

      // if (nonProcedureEventCount != 3) {
      //   if (nonProcedureEventCount == 1) {
      //     constructedDescription += 'Other Event: ';
      //   } else {
      //     constructedDescription += 'Other Events: ';
      //   }
      //   if (this.requestDetails.nonProcedureEvents.includeMismatches) {
      //     constructedDescription += 'Mismatches, ';
      //   }
      //   if (this.requestDetails.nonProcedureEvents.includeDiscards) {
      //     constructedDescription += 'Discards, ';
      //   }
      //   if (this.requestDetails.nonProcedureEvents.includeAssigns) {
      //     constructedDescription += 'Assigns, ';
      //   }
      //   if (nonProcedureEventCount == 0) {
      //     constructedDescription += 'None, ';
      //   }
      //   constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      // }
      if (nonProcedureEventCount > 0 && nonProcedureEventCount != 3) {
        constructedDescription += 'Other Events: ';
        if (this.requestDetails.nonProcedureEvents.includeMismatches) {
          constructedDescription += 'Mismatch, ';
        }
        if (this.requestDetails.nonProcedureEvents.includeDiscards) {
          constructedDescription += 'Discard, ';
        }
        if (this.requestDetails.nonProcedureEvents.includeAssigns) {
          constructedDescription += 'Assign, ';
        }

        constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      }

      if (this.requestDetails.operators !== null) {
        if (this.requestDetails.operators.length == 1) {
          constructedDescription += 'Operator: ';
        } else {
          constructedDescription += 'Operators: ';
        }
        for (let operator of this.requestDetails.operators) {
          constructedDescription += operator.name;
          if (multiClinic) {
            constructedDescription += ' (' + generalFunctions.getClinicNameFromClinicGuid(operator.clinicid) + ')';
          }
          constructedDescription += ', ';
        }
        constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      }

      if (this.requestDetails.locations !== null) {
        if (this.requestDetails.locations.length == 1) {
          constructedDescription += 'Location: ';
        } else {
          constructedDescription += 'Locations: ';
        }
        for (let location of this.requestDetails.locations) {
          constructedDescription += location.name;
          if (multiClinic) {
            constructedDescription += ' (' + generalFunctions.getClinicNameFromClinicGuid(location.clinicid) + ')';
          }
          constructedDescription += ', ';
        }
        constructedDescription = constructedDescription.slice(0, -2) + ' | ';
      }

      if (constructedDescription.slice(-3) == ' | ' || constructedDescription.slice(-3) == ' - ') {
        constructedDescription = constructedDescription.slice(0, -3);
      }

      return constructedDescription;
    }
  },
  watch: {
    requestDetails: {
      deep: true,
      immediate: true,
      handler () {
        if (this.requestDetails && !generalFunctions.isEmptyObject(this.requestDetails)) {
          this.added = false;
          this.retrieveSummaryData();
          this.description = this.constructUniqueDescription();
        }
      }
    },
    dateFilter: {
      deep: true,
      handler () {
        if (this.dashboardView) {
          this.retrieveSummaryData();
        }
      }
    },
    mode: function (newVal, oldVal) {
      this.added = false;
      this.description = this.constructUniqueDescription();
    }
  }
};