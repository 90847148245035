export default {
  props: {
    goto: String
  },
  data () {
    return {
      help_dialog_open: false,
      table_of_contents: []
    };
  },
  computed: {
    // ...mapGetters({
    //   latestError: 'latestError'
    // })

  },
  methods: {
    // exampleFunction: function () {

    // }
    gotoProp: function () {
      if (this.help_dialog_open) {
        if (this.goto) {
          document.getElementById(this.goto)?.scrollIntoView();
        }
        this.$nextTick(() => {
          if (this.goto) {
            // console.log('attempted scroll to: ' + this.goto);
            // this.$vuetify.goTo('#'+this.goto, { container: this.$refs.help_content_card });
            document.getElementById(this.goto)?.scrollIntoView();
          }
        });
      }
    }

  },
  watch: {
    // exampleVarToWatch: function (newVal, oldVal) {
    // }
    help_dialog_open: function (newVal, oldVal) {
      this.gotoProp();
    }
  },
  mounted () {
  }
};