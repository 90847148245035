import dateTimeFormatter from '@/models/common/date-time-formatter';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const isoWeek = require('dayjs/plugin/isoWeek');
const quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(quarterOfYear);
  
export default {
  name: 'WitnessDateFilter',
  data () {
    return {
      dateRadioGroup: 'this_calendar_month_so_far',
      todayString: this.today(),
      dateRangeTitle: 'This month so far',
      datesRanges: {
        today: {
          label: 'Today',
          ...this.lastNDaysDateRange(0)
        },
        last_7_days: {
          label: 'Last 7 days',
          ...this.lastNDaysDateRange(6)
        },
        last_30_days: {
          label: 'Last 30 days',
          ...this.lastNDaysDateRange(29)
        },
        last_90_days: {
          label: 'Last 90 days',
          ...this.lastNDaysDateRange(89)
        },
        last_365_days: {
          label: 'Last 365 days',
          ...this.lastNDaysDateRange(364)
        },
        this_calendar_week_so_far: {
          label: 'This week so far',
          ...this.thisCalendarDateRangeSoFar('week')
        },
        this_calendar_month_so_far: {
          id: 'this-calendar-month-so-far',              // Added for Integration test
          label: 'This month so far',
          ...this.thisCalendarDateRangeSoFar('month')
        },
        this_calendar_quarter_so_far: {
          label: 'This quarter so far',
          ...this.thisCalendarDateRangeSoFar('quarter')
        },
        this_calendar_year_so_far: {
          label: 'This year so far',
          ...this.thisCalendarDateRangeSoFar('year')
        }
      },
      dateRangeGroups: [{
        label: 'Weeks',
        specificDateRanges: ['this_calendar_week_so_far', 'last_7_days']
      }, {
        label: 'Months',
        specificDateRanges: ['this_calendar_month_so_far', 'last_30_days']
      }, {
        label: 'Quarters',
        specificDateRanges: ['this_calendar_quarter_so_far', 'last_90_days']
      }, {
        label: 'Years',
        specificDateRanges: ['this_calendar_year_so_far', 'last_365_days']
      }],
      menu: false,
      datePickerArray: [],
      startDate: undefined,
      endDate: undefined,
      jumpAmount: 1,
      jumpPeriod: 'month'
    };
  },
  computed: {
    minimumDatePickerValue: function () {
      if (this.datePickerArray.length == 1) {
        return this.datePickerArray[0];
      } else {
        return undefined;
      }
    },
    formattedFromDate: function () {
      if (this.datePickerArray.length < 1) {
        return undefined;
      }
      return dayjs.utc(this.datePickerArray[0], 'YYYY-MM-DD').format('D MMMM YYYY');
    },
    formattedToDate: function () {
      if (this.datePickerArray.length < 2) {
        return undefined;
      }
      return dayjs.utc(this.datePickerArray[1], 'YYYY-MM-DD').format('D MMMM YYYY');
    },
    dateRangeString: function () {      
      if (this.dateRadioGroup == 'custom') {
        if (this.datePickerArray.length < 2) {
          return 'Please select dates';
        }
        return dateTimeFormatter.formatDateRangeString(this.datePickerArray[0],  this.datePickerArray[1]);
      }
      
      return this.datesRanges[this.dateRadioGroup].dateRangeString;
    },
    displayedJumpPeriod: function () {
      if (this.jumpAmount > 1) {
        return 'days';
      } else {
        return this.jumpPeriod;
      }
    },
    jumpForwardPossible: function () {
      if (this.startDate && this.jumpAmount && this.jumpPeriod) {
        const formattedStartDate = dayjs(this.startDate, 'YYYY-MM-DD');
        const newStartDate = formattedStartDate.add(this.jumpAmount, this.jumpPeriod).format('YYYY-MM-DD');

        return !this.isDateStringInTheFuture(newStartDate);
      }
      return false;
    }
  },
  methods: {
    today: function () {
      return dayjs.utc().format('YYYY-MM-DD');
    },
    updateDateRangeTitle: function () {
      if (this.dateRadioGroup != 'custom') {
        this.dateRangeTitle = this.datesRanges[this.dateRadioGroup].label;
      } else if (this.jumpAmount) {
        this.dateRangeTitle = this.jumpAmount + ' ' + this.displayedJumpPeriod;
      } else {
        this.dateRangeTitle = false;
      }
    },
    constructDateRangeObject: function (startDate, endDate, jumpAmount, jumpPeriod) {
      const dateRangeString = dateTimeFormatter.formatDateRangeString(startDate, endDate);

      return {
        startDate: startDate,
        endDate: endDate,
        dateRangeString: dateRangeString,
        jumpAmount: jumpAmount,
        jumpPeriod: jumpPeriod
      };
    }, 
    lastNDaysDateRange: function (n) {
      const startDate = dayjs.utc().subtract(n, 'day').format('YYYY-MM-DD');
      const endDate = this.today();

      return this.constructDateRangeObject(startDate, endDate, n+1, 'day');
    },  
    thisCalendarDateRangeSoFar: function (dateUnit = 'week') {
      var temporaryDate = dayjs.utc();

      if (dateUnit == 'week') {
        temporaryDate = temporaryDate.startOf('isoWeek');
      } else {
        temporaryDate = temporaryDate.startOf(dateUnit);
      }

      const startDate = temporaryDate.format('YYYY-MM-DD');
      const endDate = this.today();

      return this.constructDateRangeObject(startDate, endDate, 1, dateUnit);
    },
    clearDates: function () {
      this.datePickerArray = [];
    },
    minimiseDateFilter: function () {
      this.menu = false;
    },
    clearAndCloseDateFilter: function () {
      this.dateRadioGroup = 'last_7_days';
      this.menu = false;
    },
    updateStoreDateFilter: function () {
      const dateFilter = {
        jumpAmount: this.jumpAmount,
        jumpPeriod: this.jumpPeriod, 
        startDate: this.startDate,
        endDate: this.endDate,
        dateRangeTitle: this.dateRangeTitle
      };
      this.$store.commit('updateDateFilter', dateFilter);
    },
    emitStartAndEndDate: function (startDate, endDate) {
      // console.log('WDF-CM: emitStartAndEndDate');

      this.startDate = startDate;
      this.endDate = endDate;
      this.$emit('updateDateRange', startDate, endDate);
      this.updateDateRangeTitle();

      this.updateStoreDateFilter();
    },
    emitRequestDateJump: function (startDate, endDate) {
      // console.log('WDF-CM: emitRequestDateJump');

      this.startDate = startDate;
      this.endDate = endDate;
      this.$emit('requestDateJump', startDate, endDate);
    },
    isDateStringInTheFuture: function (dateString) {
      if (dayjs(dateString, 'YYYY-MM-DD') > dayjs.utc()) {
        return true;
      } else {
        return false;
      }
    },
    jumpForward: function () {
      // console.log('WDF-CM: jumpForward');
      
      const formattedEndDate = dayjs(this.endDate, 'YYYY-MM-DD');
      const newStartDate = formattedEndDate.add(1, 'day').format('YYYY-MM-DD');
      let newEndDate = formattedEndDate.add(this.jumpAmount, this.jumpPeriod);

      if(this.jumpPeriod == 'month' || this.jumpPeriod == 'quarter') {
        newEndDate = newEndDate.endOf('month');
      }

      newEndDate = newEndDate.format('YYYY-MM-DD');

      if (this.isDateStringInTheFuture(newStartDate)) {
        return;
      }

      this.dateRadioGroup = 'custom';

      if (this.isDateStringInTheFuture(newEndDate)) {
        newEndDate = dayjs.utc().format('YYYY-MM-DD');

        if (this.jumpPeriod == 'week') {
          this.dateRadioGroup = 'this_calendar_week_so_far';
        } else if (this.jumpPeriod == 'month') {
          this.dateRadioGroup = 'this_calendar_month_so_far';
        } else if (this.jumpPeriod == 'quarter') {
          this.dateRadioGroup = 'this_calendar_quarter_so_far';
        } else if (this.jumpPeriod == 'year') {
          this.dateRadioGroup = 'this_calendar_year_so_far';
        }
      }

      this.datePickerArray = [newStartDate, newEndDate];
    },
    jumpBackward: function () {
      // console.log('WDF-CM: jumpBackward');
      
      const formattedStartDate = dayjs(this.startDate, 'YYYY-MM-DD');
      const newEndDate = formattedStartDate.subtract(1, 'day').format('YYYY-MM-DD');
      const newStartDate = formattedStartDate.subtract(this.jumpAmount, this.jumpPeriod).format('YYYY-MM-DD');

      this.datePickerArray = [newStartDate, newEndDate];
      this.dateRadioGroup = 'custom';
    }
  },
  mounted () {
    this.emitStartAndEndDate(this.datesRanges[this.dateRadioGroup].startDate, this.datesRanges[this.dateRadioGroup].endDate);
  },
  watch: {
    dateRadioGroup: function (newVal, oldVal) {
      if (this.dateRadioGroup != 'custom') {
        this.jumpAmount = this.datesRanges[this.dateRadioGroup].jumpAmount;
        this.jumpPeriod = this.datesRanges[this.dateRadioGroup].jumpPeriod;
        this.emitStartAndEndDate(this.datesRanges[this.dateRadioGroup].startDate, this.datesRanges[this.dateRadioGroup].endDate);
        if (this.menu == true) {
          this.menu = false;
        }
      } else if (this.menu == true) {
        this.jumpAmount = undefined;
        this.jumpPeriod = undefined;
      }
    },
    datePickerArray: function (newVal, oldVal) {
      // console.log('WDF-CM: watch - datePickerArray');
      
      if (this.menu == true) {
        this.jumpAmount = undefined;
        this.jumpPeriod = undefined;
      }
      if (this.datePickerArray.length == 2) {
        this.emitStartAndEndDate(this.datePickerArray[0], this.datePickerArray[1]);
      }
    }
  }
};
